<template>
  <div class="indexSetModal">

        <div class="modal-container">
          <div class="low-container">
            <div>
              <div class="bigLabel">
                预警音效：
              </div>
              <el-form style="float: left;" inline label-width="2.6rem" label-position="left">
                <template v-for="(item, index) in dataList">
                  <el-form-item v-if="item.valueType == 1" class="item" :key="index">
                    <el-switch
                      v-model="item.settingsValue.onOff"
                      active-text="开"
                      inactive-text="关"
                      :active-value="1" :inactive-value="0"
                      active-color="#13ce66"
                      inactive-color="#0F336F"
                    >
                    </el-switch>
                  </el-form-item>
                </template>
              </el-form>
            </div>
            <div>
              <div class="bigLabel">
                预警信息滚动次数
              </div>
              <el-form style="float: left;" inline label-width="2.6rem" label-position="left">
                <template v-for="(item, index) in dataList">
                  <el-form-item v-if="item.valueType == 2" class="item" :key="index">
                    <el-input class="inputNumber" v-model="item.settingsValue.times" type="number" :min=0></el-input>
                    <span>
                                            次
                                        </span>
                  </el-form-item>
                </template>
              </el-form>
            </div>
            <div class="contentBox">
              <div class="bigLabel">
                预警配置：
              </div>
              <el-form style="float: left;" label-width="1rem" label-position="left">
                <template v-for="(item, index) in dataList">
                  <template v-if="item.valueType == 3">
                    <el-form-item class="item opemItem" :label="item.settingsName" :key="index">
                      <el-switch
                        v-model="item.settingsValue.onOff"
                        active-text="开"
                        inactive-text="关"
                        :active-value="1" :inactive-value="0"
                        active-color="#13ce66"
                        inactive-color="#0F336F"
                      >
                      </el-switch>
                    </el-form-item>
                    <el-form-item label="预警阈值" class="item" :key="index">
                      <div class="firstItem" v-if="item.settingsValue.eduTemplate">
                        辖区数≥
                        <el-input class="inputNumber" v-model="item.settingsValue.minEduNum" type="number"
                                  :min=0></el-input>
                        <span class="otherSay">
                                                    {{ item.settingsValue.eduTemplate }}
                                                </span>
                      </div>
                      <div v-if="item.settingsValue.schoolTemplate">
                        单校数≥
                        <el-input class="inputNumber" v-model="item.settingsValue.minSchoolNum" type="number"
                                  :min=0></el-input>
                        <span class="otherSay">
                                                    {{ item.settingsValue.schoolTemplate }}
                                                </span>
                      </div>
                    </el-form-item>
                  </template>
                </template>
              </el-form>
            </div>
          </div>
          <div class="footer">
            <span @click="handEdit"><i class="el-icon-edit"></i>修改</span>
            <span @click="$emit('handelClose')">取消</span>
          </div>
        </div>
  </div>
</template>

<script>
import Modal from '@/components/modal/modal'
import dormChart from '@/components/modal/dormChart'

export default {
  name: 'indexSetModal',
  components: {
    Modal,
    dormChart
  },
  props: {
    modelSize: String,
    data: {
      type: Array,
      default: () => {
        return [
          {
            schoolName: '福州市晋安区第二实验小学',
            dormNum: 5, // 宿舍楼数
            offlineNum: 1000, // 寄宿生人数
            onlineNum: 500 // 在寝人数
          }
        ]
      }
    }
  },
  data () {
    return {
      value: 1,
      values: '校园报警',
      numValue: 0,
      dataList: [],
      modelShow: false
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo
    }
  },
  methods: {
    show () {
      this.modelShow = true
    },

    open () {
      Object.assign(this.$data, this.$options.data())
      this.getBaseSet()
    },
    // 获取设置列表
    getBaseSet () {
      var params = {
        eduId: this.userInfo.organizationIdStr,
        settingsType: 2
      }
      this.$store.dispatch('indexSet/GetBaseSet', params).then(
        res => {
          this.dataList = res
        }
      )
    },
    // 更新设置
    handEdit () {
      this.$store.dispatch('indexSet/UpdateHomepageSettings', this.dataList).then(
        res => {
          this.$message.success('修改成功')
        }
      )
    }

  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  overflow: hidden;
  padding: 0.3rem 0.3rem;

  .low-container {
    height: 6.5rem;
    overflow: auto;

    > div {
      overflow: hidden;
    }
  }

  .modal-title {
    text-align: center;
    color: #fff;
    font-size: 0.28rem;
    font-family: Microsoft YaHei;
  }

  .modal-list {
    margin-top: 0.5rem;

    h4 {
      font-size: 0.16rem;
      font-weight: 700;
      color: #00A2FF;
    }
  }
}
</style>
<style lang='scss'>
.indexSetModal {
  * {
    font-size: 0.16rem;
  }

  .modal-list .el-table,
  .modal-list .el-table tr,
  .modal-list .el-table td,
  .modal-list .el-table th {
    background-color: transparent !important;
    border: none;
    text-align: center;
  }

  .modal-list .el-table {
    margin-top: 0.35rem;
  }

  .modal-list .el-table::before {
    background-color: transparent !important;
  }

  .modal-list .el-table th {
    font-size: 0.16rem;
    color: #fff;
    height: 0.4rem;
  }

  .modal-list .el-table td {
    font-size: 0.14rem;
    color: #B8C8E9;
  }

  .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar { /*滚动条整体样式*/
    width: 0.1rem;
    height: 0.1rem;
  }

  .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
    background: #395283;
  }

  .modal-list .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track { /*滚动条里面轨道*/
    background: #152952;
    border-radius: 0.1rem;
  }

  .item > .el-form-item__label {
    color: #00C6FF;
  }

  .item {
    color: #fff;
  }

  .contentBox .opemItem:first-child {
    margin-top: 0;
  }

  .opemItem {
    margin-top: 0.52rem;
  }

  .item .inputNumber {
    width: 0.9rem;
    height: 0.34rem;
  }

  .item .el-input__inner {
    padding-right: 0;
  }

  .firstItem {
    margin-bottom: 0.16rem;
  }

  .item .el-switch__core {
    border-width: 0;
  }

  .item .el-switch__label {
    color: #fff;
  }

  .item input {
    display: inline-block;
    width: 0.9rem;
    height: 0.34rem;
    background: #0759A0;
    color: #fff;
  }

  .bigLabel {
    color: #fff;
    width: 16%;
    float: left;
    line-height: 0.32rem;
    text-align: right;
    padding-right: 0.2rem;
  }

  .selectStyle {
    width: 2.6rem;
    height: 0.3rem;
    background: #0C508C;
    color: #FFFFFF;
  }

  .selectStyle option {
    background: #fff;
    color: #0C508C;
  }

  .imgBox {
    width: 2.6rem;
    height: 0.74rem;
    background: url('../../../assets/img/modal/imgBk.png') no-repeat;
    background-size: 100%;
    padding: 0.17rem 0;
    text-align: center;
    box-sizing: border-box;

    img {
      width: 0.4rem;
      height: 0.4rem;
    }
  }

  .otherSay {
    display: inline-block;
    width: 6.2rem;
    height: 0.36rem;
    line-height: 0.36rem;
    background: #063D7C;
  }

  .longer {
    width: 5.55rem
  }

  .footer {
    text-align: center;
    padding-top: 0.16rem;

    > span {
      cursor: pointer;
      display: inline-block;
      width: 1.16rem;
      height: 0.38rem;
      line-height: 0.38rem;
      color: #fff;
      background: #136DBB;
      border-radius: 0.04rem;
    }

    > span:first-child {
      margin-right: 0.34rem;
    }
  }
}

</style>
