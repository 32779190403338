<template>
    <transition name="slide">
        <div class="modal" v-if="modelShow" :style="{zIndex: zIndex}">
            <div class="mask" @click.stop="close"></div>
            <div class="modal-dialog" :class="sizes">
                <div class="close" @click.stop="close"></div>
                <div class="modal-body modal-scrollbar" >
					<slot name="body"></slot>
				</div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name:'modal',
    props:{
        modelShow:Boolean,
        modelSize:{
            type:String,
            default:'min' //min、max
        },
        modalScrollbar:{
            type:Boolean,
            default:false
        },
        zIndex: {
            type: Number,
            default: 5
        }
    },
    watch: {
        modelShow (newVal, oldVue) {
            this.$nextTick(() => {
                if (newVal) {
                    setTimeout(() => {
                        this.$emit('open')
                    }, 600)
                    
                } 
            })
        }
    },
    computed:{
        sizes(){
            return this.modelSize == 'max' ? 'modal-dialog-max' : 'modal-dialog-min'
        },
        isScrollbar(){
            return this.modalScrollbar ? 'modal-scrollbar' : ''
        }
    },
    methods: {
        close(){
            this.$emit('handelClose')
            this.$emit('close')
        }
    },
    mounted(){
        this.$nextTick(() => {
            const body = document.querySelector("body");
            if (body.append) {
                body.append(this.$el);
            } else {
                body.appendChild(this.$el);
            }
        })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/mixin.scss';
.modal {
    @include position(fixed);
    z-index: 10;
    transition: all .5s;
    .mask {
        @include position(fixed);
        background:#000;
        opacity: .5;
    }
    &.slide-enter-active {
        top: 0;
    }
    &.slide-leave-active {
        top: -100%;
    }
    &.slide-enter {
        top: -100%;
    }
    .modal-dialog-min{
        @include position(absolute, 50%, 50%, 7.44rem, 5.20rem);
        background:rgba(0,0,0,0.7) url(../../assets/img/modal-min.png) no-repeat;
        background-size: cover;
    }
    .modal-dialog-max{
        @include position(absolute, 50%, 50%, 13rem, 7.80rem);
        background: url(../../assets/img/modal-max.png) no-repeat;
        background-size: cover;
    }
    .modal-dialog {
        transform: translate(-50%, -50%);
        border-radius:.08rem;
        animation: 4s borderLight linear;
        animation-delay:0s;
        box-sizing: border-box;
        padding:0.1rem 0px 0.1rem 0;
        .close{
            width:.4rem;
            height:.4rem;
            background:url(../../assets/img/modal-close.png) no-repeat;
            background-size:cover;
            position: absolute;
            right:-0.58rem;
            top:-0.46rem;
            cursor: pointer;
            transition: transform .2s;
            opacity:0.6;
            &:hover {
                animation:circleRoate 5s infinite linear;  
                opacity:1;
            }
        }
        @keyframes circleRoate{  
            from{transform: rotate(0deg);}  
            to{transform: rotate(360deg);}  
        }
        @keyframes borderLight{
            0%{
                box-shadow: 0 0 0.8rem rgba($color: #0185d9, $alpha: 1.0);
            }
            25%{
                box-shadow: 0 0 0.6rem rgba($color: #0185d9, $alpha: 0.8);
            }
            50%{
                box-shadow: 0 0 0.4rem rgba($color: #0185d9, $alpha: 0.5);
            }
            70%{
                box-shadow: 0 0 0.2rem rgba($color: #0185d9, $alpha: 0.2);
            }
            100%{
                 box-shadow: 0 0 0 rgba($color: #0185d9, $alpha: 0);
            }
        } 
        .modal-scrollbar{
            height:100%;
            overflow: hidden;
            overflow-y:auto;
        }
        .modal-scrollbar::-webkit-scrollbar {/*滚动条整体样式*/
            width: 1px;    
            height: 1px;
        }
        .modal-scrollbar::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
            background: #01ddf3;
        }
        .modal-scrollbar::-webkit-scrollbar-track {/*滚动条里面轨道*/
            background: #0185d9;
        }
    }
}
</style>